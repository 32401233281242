export function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/Reduce#Grouping_objects_by_a_property#Grouping_objects_by_a_property
export function groupBy(arr, property) {
  return arr.reduce(function (acc, obj) {
    var key = obj[property]
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(obj)
    return acc
  }, {})
}

export function sessionToColour(session) {
  switch (session) {
    case 'M':
      return 'bg-yellow-400'
    case 'A':
      return 'bg-blue-400'
    case 'E':
      return 'bg-gray-400'
    default:
      return 'bg-gray-400'
  }
}
