import React, { Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Menu as HUIMenu, Transition } from '@headlessui/react'

const getChildrenOnDisplayName = (children, displayName) =>
  React.Children.map(children, (child) =>
    child.type.displayName === displayName ? child : null
  )

export default function Menu({ children, ...props }) {
  const trigger = getChildrenOnDisplayName(children, 'Trigger')
  const items = getChildrenOnDisplayName(children, 'Items')

  return (
    <HUIMenu as="div" className="relative flex-none">
      <HUIMenu.Button as="div">
        <span className="sr-only">Open menu</span>
        {trigger}
      </HUIMenu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-300"
        enterFrom="transform opacity-0 scale-75"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-300"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-75"
      >
        <HUIMenu.Items
          className="absolute xs:left-0 md:right-0 z-10 mt-2 w-32 xs:origin-top-left md:origin-top-right rounded-md bg-indigo-600 focus:outline-none transition ease-out"
          transition="true"
        >
          {items}
        </HUIMenu.Items>
      </Transition>
    </HUIMenu>
  )
}

const Item = ({ ...props }) => {
  return (
    <HUIMenu.Item className="flex items-center">
      <button
        className="ui-active:bg-indigo-50 disabled:opacity-50 disabled:hover:bg-indigo-600 block px-3 py-1 text-sm leading-6 text-white w-full hover:bg-indigo-500"
        onClick={props.onClick}
        disabled={props.disabled}
      >
        <FontAwesomeIcon icon={props.icon} className="mr-2 text-white/60" />
        {props.label}
      </button>
    </HUIMenu.Item>
  )
}
Item.displayName = 'Item'
Menu.Item = Item

const Items = ({ children }) => <div className="p-1">{children}</div>
Items.displayName = 'Items'
Menu.Items = Items

const Trigger = ({ children }) => <div>{children}</div>
Trigger.displayName = 'Trigger'
Menu.Trigger = Trigger

const Divider = () => <div class="my-1 h-px bg-white/50" role="none"></div>
Divider.displayName = 'Divider'
Menu.Divider = Divider
