import { makeGetCall } from './External'

export function fetchMe(token) {
  const url = '/auth/users/me/'
  return makeGetCall(token, url)
}

export function fetchUsers(token) {
  const url = '/auth/users/'
  return makeGetCall(token, url)
}

export function fetchUser(token, id) {
  const url = `/auth/users/${id}`
  return makeGetCall(token, url)
}
