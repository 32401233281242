import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSunrise } from '@fortawesome/pro-solid-svg-icons'
import ColouredPill from './ColouredPill'
import { classNames } from '../utils'

export default function MorningPill(props) {
  let withText = !props.hasOwnProperty('small')
  return (
    <ColouredPill
      className={classNames(
        props.className,
        'bg-yellow-100 text-yellow-800 ring-2 ring-yellow-800'
      )}
    >
      <span className="flex items-center">
        <FontAwesomeIcon icon={faSunrise} />
        {withText && <span className="pl-2 hidden sm:inline">Morning</span>}
      </span>
    </ColouredPill>
  )
}
