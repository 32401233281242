import { useContext } from 'react'
import { UserProfileContext } from '../components/UserProfileProvider'

export default function useUserProfile() {
  const profile = useContext(UserProfileContext)
  if (profile === undefined) {
    throw new Error(
      'useUserProfile can only be used inside UserProfileProvider'
    )
  }
  const { accessToken, isStaff } = profile

  return { accessToken, isStaff }
}
