import React, { useEffect, useState } from 'react'
import EventList from '../components/EventList'
import useBookingService from '../hooks/BookingService'
import useUserProfile from '../hooks/UserProfile'

function BookingList() {
  const { fetchUpcomingBookings } = useBookingService()
  const { accessToken } = useUserProfile()
  const [bookings, setBookings] = useState([])

  useEffect(() => {
    setBookings(fetchUpcomingBookings(accessToken))
  }, [fetchUpcomingBookings, accessToken])

  return <EventList bookings={bookings} />
}

export default BookingList
