import React, { useEffect, useState } from 'react'
import { Await, Link, useParams } from 'react-router-dom'
import { addMonths, format, getMonth, getYear, subMonths } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendarCirclePlus,
  faCalendarDays,
  faChevronLeft,
  faChevronRight,
  faList,
} from '@fortawesome/pro-solid-svg-icons'
import Button from '../components/Button'
import EventList from '../components/EventList'
import MonthCalendar from '../components/MonthCalendar'
import { classNames } from '../components/utils'
import useBookingService from '../hooks/BookingService'
import useUserProfile from '../hooks/UserProfile'

export default function Events() {
  const { month = getMonth(new Date()) + 1, year = getYear(new Date()) } =
    useParams()
  const [bookings, setBookings] = useState([])
  const [showCalendar, setShowCalendar] = useState(true)
  const [showList, setShowList] = useState(false)
  const { fetchBookingsForMonth } = useBookingService()
  const { accessToken } = useUserProfile()
  const firstDayOfMonth = new Date(year, month - 1, 1)
  const monthName = format(firstDayOfMonth, 'LLLL')
  const shortMonthName = format(firstDayOfMonth, 'LLL')
  const shortYear = format(year, 'yy')
  const firstDayOfLastMonth = subMonths(firstDayOfMonth, 1)
  const firstDayOfNextMonth = addMonths(firstDayOfMonth, 1)

  function toggleView() {
    setShowCalendar(!showCalendar)
    setShowList(!showList)
  }

  useEffect(() => {
    setBookings(fetchBookingsForMonth(accessToken, year, month))
  }, [fetchBookingsForMonth, accessToken, year, month])

  return (
    <>
      <header className="py-4">
        <div className="grid grid-cols-3 w-full">
          <div className="inline-flex rounded" role="group">
            <button
              type="button"
              className={classNames(
                'inline-flex items-center rounded-l px-3 py-2 text-sm font-semibold shadow-sm',
                showCalendar
                  ? 'bg-indigo-600 text-white'
                  : 'bg-white hover:bg-indigo-500 hover:text-white'
              )}
              onClick={() => toggleView()}
              disabled={showCalendar}
            >
              <FontAwesomeIcon icon={faCalendarDays} />
              <span className="pl-2 hidden sm:inline">Calendar</span>
            </button>
            <button
              type="button"
              className={classNames(
                'inline-flex items-center rounded-r px-3 py-2 text-sm font-semibold shadow-sm',
                showList
                  ? 'bg-indigo-600 text-white'
                  : 'bg-white hover:bg-indigo-500 hover:text-white'
              )}
              onClick={() => toggleView()}
              disabled={showList}
            >
              <FontAwesomeIcon icon={faList} />
              <span className="pl-2 hidden sm:inline">List</span>
            </button>
          </div>

          <div className="inline-flex justify-center" role="group">
            <div className="inline-flex items-center">
              <Link
                to={
                  '/events/' +
                  getYear(firstDayOfLastMonth) +
                  '/' +
                  (getMonth(firstDayOfLastMonth) + 1)
                }
                className="h-full"
              >
                <button
                  type="button"
                  className="flex h-full items-center justify-center rounded-l py-2 pl-3 pr-4 bg-indigo-600 text-white hover:bg-indigo-500 shadow-sm"
                >
                  <span className="sr-only">Previous month</span>
                  <FontAwesomeIcon icon={faChevronLeft} />
                </button>
              </Link>
              <p className="text-base text-sm font-semibold leading-6 text-gray-900 px-3.5 border-t border-b border-indigo-600 h-full items-center inline-flex bg-white">
                <span className="sm:block md:hidden">
                  {shortMonthName} '{shortYear}
                </span>
                <span className="hidden md:inline">
                  {monthName} {year}
                </span>
              </p>
              <Link
                to={
                  '/events/' +
                  getYear(firstDayOfNextMonth) +
                  '/' +
                  (getMonth(firstDayOfNextMonth) + 1)
                }
                className="h-full"
              >
                <button
                  type="button"
                  className="flex h-full items-center justify-center rounded-r py-2 pl-4 pr-3 bg-indigo-600 text-white hover:bg-indigo-500 shadow-sm"
                >
                  <span className="sr-only">Next month</span>
                  <FontAwesomeIcon icon={faChevronRight} />
                </button>
              </Link>
            </div>
          </div>

          <div>
            <div className="flex float-right h-full">
              <Link to={'/events/new'}>
                <Button className="h-full justify-center rounded py-2 pl-3 pr-4 bg-indigo-600 text-white hover:bg-indigo-500 text-sm font-semibold shadow-sm">
                  <span className="sr-only">Add event</span>
                  <FontAwesomeIcon icon={faCalendarCirclePlus} />
                  <span className="pl-2 hidden sm:inline">Add event</span>
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </header>

      {showCalendar && (
        <React.Suspense>
          <Await resolve={bookings}>
            <MonthCalendar month={month} year={year} />
          </Await>
        </React.Suspense>
      )}

      {showList && <EventList bookings={bookings} />}
    </>
  )
}
