import React, { Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faXmark } from '@fortawesome/pro-solid-svg-icons'
import { Disclosure, CloseButton } from '@headlessui/react'
import { NavLink } from 'react-router-dom'
import LoginButton from '../components/LoginButton'
import UserProfileMenu from '../components/UserProfileMenu'
import useUserProfile from '../hooks/UserProfile'
import { classNames } from './utils'
import { useAuth0 } from '@auth0/auth0-react'

const navigation = [
  { name: 'Events', href: '/events', staff_only: false },
  { name: 'History', href: '/history', staff_only: false },
  { name: 'Facilities', href: '/facilities', staff_only: false },
  { name: 'Team', href: '/team', staff_only: false },
  { name: 'Contact Us', href: '/contact', staff_only: false },
  { name: 'Book', href: '/events/new', staff_only: false },
  { name: 'Users', href: '/users', staff_only: true },
]

export default function NavHeader() {
  const { isAuthenticated, isLoading } = useAuth0()
  const { isStaff } = useUserProfile()

  // TODO: This needs similar styling (i.e. refactor) so that the render is clean during login.
  if (isLoading) {
    return <div>Loading ...</div>
  }

  return (
    <>
      <Disclosure as="nav" className="bg-indigo-900">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 items-center justify-between">
                <div className="flex items-center w-full">
                  <div className="flex-shrink-0">
                    <NavLink to="/" end>
                      <img
                        src="/images/header-logo.png"
                        alt="Reading Room Logo"
                      />
                    </NavLink>
                  </div>
                  <div className="hidden md:block w-full flex-grow">
                    <div className="ml-10 flex items-baseline space-x-4">
                      {navigation.map(
                        (item) =>
                          (!item.staff_only || isStaff) && (
                            <NavLink
                              to={item.href}
                              key={item.name}
                              className={({ isActive }) => {
                                const base =
                                  'rounded-md px-3 py-2 text-sm font-medium '
                                if (isActive) {
                                  return classNames(
                                    base,
                                    'active bg-indigo-800 text-white'
                                  )
                                } else {
                                  return classNames(
                                    base,
                                    'text-indigo-300 hover:bg-indigo-700 hover:text-white'
                                  )
                                }
                              }}
                              end
                            >
                              {item.name}
                            </NavLink>
                          )
                      )}
                    </div>
                  </div>
                  <div className="hidden md:block">
                    {!isAuthenticated && <LoginButton />}
                    {isAuthenticated && <UserProfileMenu />}
                  </div>
                </div>
                <div className="-mr-2 flex md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-indigo-900 p-2 text-indigo-400 hover:bg-indigo-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-800">
                    <span className="sr-only">Open menu</span>
                    {open ? (
                      <FontAwesomeIcon icon={faXmark} />
                    ) : (
                      <FontAwesomeIcon icon={faBars} />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>
            <Disclosure.Panel className="md:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3 divide-y">
                <div>
                  {navigation.map((item) => (
                    <div key={item.name}>
                      <CloseButton
                        as={NavLink}
                        theme="nav"
                        to={item.href}
                        end
                        className="text-indigo-300 hover:bg-indigo-700 hover:text-white px-3 py-2 font-semibold whitespace-nowrap text-sm"
                      >
                        {item.name}
                      </CloseButton>
                    </div>
                  ))}
                </div>
                <div className="pt-2">
                  {!isAuthenticated && <LoginButton />}
                  {isAuthenticated && <UserProfileMenu />}
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  )
}
