import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { useAuth0 } from '@auth0/auth0-react'
import Button from '../components/Button'
import RRCheckbox from '../components/form/RRCheckbox'
import RRDateInput from '../components/form/RRDateInput'
import RRTextArea from '../components/form/RRTextArea'
import RRSelect from '../components/form/RRSelect'
import RRTextInput from '../components/form/RRTextInput'
import MessageBox from '../components/MessageBox'
import NotLoggedInRedirect from '../components/NotLoggedInRedirect'
import useBookingService from '../hooks/BookingService'
import useUserProfile from '../hooks/UserProfile'
import dayjs from 'dayjs'

function BookingForm() {
  const [createdId, setCreatedId] = useState(false)
  const { accessToken } = useUserProfile()
  const { isAuthenticated, isLoading } = useAuth0()
  const { createBooking, checkSessionAvailability } = useBookingService()
  const BookingSchema = Yup.object().shape({
    date: Yup.date()
      .min(new Date(), 'You must select a booking date that is in the future.')
      .required(),
    summary: Yup.string()
      .min(5, 'The booking title must be at least 5 characters long.')
      .required('You must provide a title for your booking.'),
    session: Yup.string()
      .matches(/(M|A|E)/, 'You must select a valid session.')
      .required('You must select a session.')
      .test(
        'is-available',
        'This session is already booked',
        async (value, ctx) =>
          await checkSessionAvailability(
            accessToken,
            dayjs(ctx.parent.date).format('YYYY-MM-DD'),
            value
          )
            .then(function (res) {
              return res.available === true
            })
            .catch(function (res) {
              return ctx.createError({
                message:
                  'There was an error checking whether this session is available.',
              })
            })
      ),
    description: Yup.string().required(
      'You must enter some details about the event.'
    ),
  })

  if (!isLoading && !isAuthenticated) {
    return <NotLoggedInRedirect />
  }

  return createdId ? (
    <MessageBox title="Your booking has been received">
      <p>
        Your booking has been received. Click{' '}
        <Link
          to={`/event/${createdId}`}
          className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
        >
          here
        </Link>{' '}
        to review or update your booking.
      </p>
      <p>
        Your booking will be reviewed in due course. You will receive an email
        update regarding the status of your booking.
      </p>
    </MessageBox>
  ) : (
    <div>
      <Formik
        initialValues={{
          date: dayjs(new Date()).format('YYYY-MM-DD'),
          summary: '',
          session: '',
          description: '',
          need_tables: false,
          need_crockery: false,
          is_private: false,
        }}
        validationSchema={BookingSchema}
        onSubmit={(values, { setSubmitting }) => {
          createBooking(accessToken, values)
            .then(function (res) {
              setCreatedId(res.id)
            })
            .catch(function (res) {
              throw res
            })
          setSubmitting(false)
        }}
      >
        <Form>
          <div className="text-wrapper border rounded-lg bg-white space-y-2 px-4 sm:px-6 py-4 sm:py-6">
            <div className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-x-8">
                <div>
                  <h2 className="text-base font-semibold leading-relaxed text-gray-900">
                    Booking Basics
                  </h2>
                  <p className="mt-1 text-sm leading-relaxed text-gray-600">
                    This information will be visible to the public unless you
                    select <b>private</b> below.
                  </p>
                </div>
                <div className="grid md:col-span-2 mt-6">
                  <div className="mt-2 space-y-8">
                    <RRDateInput
                      name="date"
                      label="Date"
                      min={dayjs().add(1, 'day').format('YYYY-MM-DD')}
                    />

                    <RRSelect name="session" label="Session">
                      <option value="">Select a session</option>
                      <option value="M">Morning</option>
                      <option value="A">Afternoon</option>
                      <option value="E">Evening</option>
                    </RRSelect>

                    <RRTextInput
                      name="summary"
                      label="Booking Title"
                      placeholder="How should we refer to your booking (e.g. 'Tommy's Birthday')?"
                    />

                    <RRTextArea
                      name="description"
                      label="Booking Details"
                      placeholder="Tell us a little bit about what you're planning to use the Reading Room for."
                    />
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-x-8">
                <div>
                  <h2 className="text-base font-semibold leading-relaxed text-gray-900">
                    Extras (no extra charge)
                  </h2>
                  <p className="mt-1 text-sm leading-relaxed text-gray-600">
                    Would you like to book any extras, at no extra charge?
                    Letting us know will ensure we have the necessary items
                    available, and helps us plan for setting up for your
                    booking.
                  </p>
                </div>
                <div className="grid md:col-span-2">
                  <div className="mt-2">
                    <fieldset className="my-2 space-y-2">
                      <RRCheckbox
                        name="need_tables"
                        help_text="I'd like to use the Reading Room's tables for my event."
                      >
                        Tables
                      </RRCheckbox>
                      <RRCheckbox
                        name="need_crockery"
                        help_text="I'd like to use the Reading Room's crockery for my event."
                      >
                        Crockery
                      </RRCheckbox>
                    </fieldset>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-x-8">
                <div>
                  <h2 className="text-base font-semibold leading-relaxed text-gray-900">
                    Privacy
                  </h2>
                  <p className="mt-1 text-sm leading-relaxed text-gray-600">
                    Public events will be shown on the public Reading Room's
                    booking calendar. Private events will only be visible to
                    administrators.
                  </p>
                </div>

                <div className="grid md:col-span-2">
                  <div className="mt-2">
                    <RRCheckbox
                      name="is_private"
                      help_text="Is this event private?"
                    >
                      Private
                    </RRCheckbox>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-6 flex items-center justify-end">
              <Button type="submit">Request Booking</Button>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  )
}

export default BookingForm
