import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons'

export default function MessageBox({ children, ...props }) {
  return (
    <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
      <div className="px-4 py-5 sm:px-6">
        <h1 className="text-2xl flex items-center text-green-600 font-bold">
          <FontAwesomeIcon icon={faCircleCheck} />
          <div className="pl-2">{props.title}</div>
        </h1>
      </div>
      <div className="px-4 py-5 sm:p-6 text-sm space-y-2">{children}</div>
    </div>
  )
}
