import React from 'react'
import { classNames } from '../utils'

export default function ColouredPill(props) {
  const classes =
    'inline-flex rounded-full px-2 text-xs font-semibold leading-5'
  return (
    <div className={classNames(classes, props.className)}>{props.children}</div>
  )
}
