import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { Link } from 'react-router-dom'
import SessionPill from './pills/SessionPill'

export default function PublicBookingListItem(props) {
  const booking = props.booking

  return (
    <li>
      <Link to={`/event/${booking.id}`} className="block hover:bg-gray-50">
        <div className="flex items-center">
          <div className="w-full">
            <div className="px-4 py-2 sm:px-6">
              <div className="flex items-center justify-between">
                <p className="truncate text-sm font-medium">
                  {booking.summary}
                </p>
              </div>
            </div>
            <div className="px-4 py-1 sm:px-6 sm:flex sm:justify-between text-sm font-light italic">
              {booking.description}
            </div>
            <div className="px-4 py-1 sm:px-6 sm:flex sm:justify-between">
              <div className="flex items-center text-sm text-gray-500">
                <FontAwesomeIcon
                  icon={faCalendar}
                  className="mr-1.5 text-gray-400"
                />
                {booking.date}
              </div>
            </div>
            <div className="px-4 py-3 sm:px-6 sm:flex sm:justify-between">
              <div className="flex-wrap items-center space-x-2 space-y-2">
                <SessionPill session={booking.session} />
              </div>
            </div>
          </div>
          <div>
            <FontAwesomeIcon
              icon={faChevronRight}
              className="mr-5 text-gray-400"
            />
          </div>
        </div>
      </Link>
    </li>
  )
}
