import React from 'react'
import { classNames } from './utils'

const themeStyles = {
  default: 'bg-indigo-600 hover:bg-indigo-500 text-white',
  danger: 'bg-red-600 hover:bg-red-500 text-white',
  deemphasise:
    'bg-white hover:bg-gray-50 text-gray-900 ring-1 ring-inset ring-gray-300',
  nav: 'text-base font-medium text-indigo-300 hover:bg-indigo-700 hover:text-white',
}

const Button = React.forwardRef((props, ref) => {
  const themeClasses = themeStyles[props.theme] || themeStyles['default']

  return (
    <button
      type="button"
      className={classNames(
        'rounded px-3 py-2 text-sm font-semibold shadow-sm whitespace-nowrap',
        themeClasses,
        props.className
      )}
      ref={ref}
      {...props}
    >
      {props.children}
    </button>
  )
})

export default Button
