import React from 'react'
import {
  Description,
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from '@headlessui/react'

const getChildrenOnDisplayName = (children, displayName) =>
  React.Children.map(children, (child) =>
    child.type.displayName === displayName ? child : null
  )

export default function Modal({ children, ...props }) {
  const title = getChildrenOnDisplayName(children, 'Title')
  const body = getChildrenOnDisplayName(children, 'Body')
  const buttons = getChildrenOnDisplayName(children, 'Buttons')

  return (
    <Transition show={props.open}>
      <Dialog onClose={props.onClose} className="relative z-50">
        <TransitionChild
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
          <TransitionChild
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel className="rounded-lg bg-white sm:w-full sm:max-w-lg">
              {title}
              {body}
              {buttons}
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  )
}

const Title = ({ children }) => (
  <div className="rounded-t-lg bg-indigo-600 p-6">
    <DialogTitle className="font-bold text-white items-center">
      {children}
    </DialogTitle>
  </div>
)
Title.displayName = 'Title'
Modal.Title = Title

const Body = ({ children }) => (
  <Description className="px-6 pt-6 space-y-3">{children}</Description>
)
Body.displayName = 'Body'
Modal.Body = Body

const Buttons = ({ children }) => (
  <div className="flex px-6 pb-6 pt-3 space-x-3 justify-end">{children}</div>
)
Buttons.displayName = 'Buttons'
Modal.Buttons = Buttons
