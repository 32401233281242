import {
  createBooking,
  fetchBookingsForMonth,
  fetchUpcomingBookings,
  fetchBooking,
  approveBooking,
  deleteBooking,
  rejectBooking,
  resetBooking,
  checkSessionAvailability,
} from '../services/Bookings'

export default function useUserService() {
  return {
    createBooking,
    fetchBookingsForMonth,
    fetchUpcomingBookings,
    fetchBooking,
    approveBooking,
    deleteBooking,
    rejectBooking,
    resetBooking,
    checkSessionAvailability,
  }
}
