import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSunset } from '@fortawesome/pro-solid-svg-icons'
import ColouredPill from './ColouredPill'
import { classNames } from '../utils'

export default function EveningPill(props) {
  let withText = !props.hasOwnProperty('small')
  return (
    <ColouredPill
      className={classNames(
        props.className,
        'bg-gray-100 text-gray-800 ring-2 ring-gray-800'
      )}
    >
      <span className="flex items-center">
        <FontAwesomeIcon icon={faSunset} />
        {withText && <span className="pl-2 hidden sm:inline">Evening</span>}
      </span>
    </ColouredPill>
  )
}
