import React from 'react'
import { Link } from 'react-router-dom'
import Button from './Button'
import InfoBox from './alert/InfoBox'

export default function NoEvents() {
  return (
    <InfoBox>
      <p className="text-indigo-700 flex items-center w-full justify-between">
        <span className="pr-6">
          There are currently no scheduled events. Perhaps you'd like to book
          one?
        </span>
        <Link to={'/events/new'} className="float-right">
          <Button>Book now &rarr;</Button>
        </Link>
      </p>
    </InfoBox>
  )
}
