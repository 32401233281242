import React, { useEffect, useState } from 'react'
import Markdown from 'react-markdown'
import file from '../md/terms.md'

function Terms() {
  const [markdown, setMarkdown] = useState('')

  useEffect(() => {
    fetch(file)
      .then((res) => res.text())
      .then((text) => setMarkdown(text))
  }, [])

  return (
    <div className="text-wrapper text-sm text-gray-900 text-justify border rounded-lg bg-white space-y-4 px-4 sm:px-6 py-4 sm:py-6 leading-relaxed">
      <div className="prose prose-stone w-full max-w-none">
        <Markdown>{markdown}</Markdown>
      </div>
    </div>
  )
}

export default Terms
