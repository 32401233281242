import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/pro-solid-svg-icons'
import { useMatches, Link } from 'react-router-dom'

export default function Breadcrumbs() {
  let matches = useMatches()
  let crumbs = matches
    .filter((match) => Boolean(match.handle?.crumb))
    .map((match) => match.handle.crumb(match.data))

  return (
    <>
      {crumbs.length > 0 && (
        <nav className="flex" aria-label="Breadcrumb">
          <ol className="flex space-x-4 rounded-md bg-white px-6 shadow mb-2 sm:mb-4">
            <li className="flex">
              <div className="flex items-center">
                <Link to="/" className="text-gray-400 hover:text-gray-500">
                  <FontAwesomeIcon icon={faHome} />
                  <span className="sr-only">Home</span>
                </Link>
              </div>
            </li>
            {crumbs.map((crumb, index) => (
              <li key={index} className="flex">
                <div className="flex items-center">
                  <svg
                    className="h-full w-6 flex-shrink-0 text-gray-200"
                    viewBox="0 0 24 44"
                    preserveAspectRatio="none"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                  </svg>

                  <span className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                    {crumb}
                  </span>
                </div>
              </li>
            ))}
          </ol>
        </nav>
      )}
    </>
  )
}
