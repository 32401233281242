import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons'

export default function Error({ meta, ...props }) {
  return (
    <div
      className="flex items-center error text-sm text-red-600 font-semibold mt-1"
      {...props}
    >
      <FontAwesomeIcon icon={faTriangleExclamation} className="mr-1" />
      {meta.error}
    </div>
  )
}
