import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons'

export default function InfoBox({ children, ...props }) {
  return (
    <div className="border rounded-lg bg-indigo-50 p-4">
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <FontAwesomeIcon icon={faCircleInfo} className="text-indigo-400" />
        </div>
        <div className="ml-3 flex-1 w-full">{children}</div>
      </div>
    </div>
  )
}
