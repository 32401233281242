import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUtensils } from '@fortawesome/pro-solid-svg-icons'
import ColouredPill from './ColouredPill'
import { classNames } from '../utils'

export default function CrockeryPill(props) {
  return (
    <ColouredPill
      className={classNames(
        props.className,
        'bg-pink-100 text-pink-800 ring-2 ring-pink-800'
      )}
    >
      <span className="flex items-center">
        <FontAwesomeIcon icon={faUtensils} />
        <span className="pl-2 hidden sm:inline">Crockery</span>
      </span>
    </ColouredPill>
  )
}
