import React, { useEffect, useState } from 'react'
import Markdown from 'react-markdown'
import {
  AdvancedMarker,
  APIProvider,
  ControlPosition,
  Map,
  MapControl,
  Pin,
} from '@vis.gl/react-google-maps'
import file from '../md/contact.md'

const LATITUDE = 51.0582065
const LONGITUDE = -2.6740168

function Contact() {
  const [markdown, setMarkdown] = useState('')

  useEffect(() => {
    fetch(file)
      .then((res) => res.text())
      .then((text) => setMarkdown(text))
  }, [])

  return (
    <div className="text-wrapper text-sm text-gray-900 text-justify border rounded-lg bg-white space-y-4 px-4 sm:px-6 py-4 sm:py-6 leading-relaxed">
      <div className="prose prose-stone w-full max-w-none">
        <Markdown>{markdown}</Markdown>
      </div>
      <div className="size-[350px] md:size-[600px] lg:size-[800px] xl:size-[1000px] flex justify-center">
        <APIProvider apiKey={`${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}>
          <Map
            mapId={'3ec0b73bc443663c'}
            defaultCenter={{ lat: LATITUDE, lng: LONGITUDE }}
            defaultZoom={17}
            gestureHandling={'greedy'}
            //disableDefaultUI={true}
          >
            <AdvancedMarker position={{ lat: LATITUDE, lng: LONGITUDE }}>
              <Pin
                background={'#312e81'} // indigo-900
                borderColor={'#4f46e5'} // indigo-600
                glyphColor={'#a5b4fc'} // indigo-300
              />
            </AdvancedMarker>
            <MapControl position={ControlPosition.TOP_LEFT}></MapControl>
          </Map>
        </APIProvider>
      </div>
    </div>
  )
}

export default Contact
