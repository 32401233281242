import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import {
  faCalendar,
  faRightFromBracket,
  faUser,
} from '@fortawesome/pro-solid-svg-icons'
import Menu from '../components/Menu'

export default function UserProfileMenu(props) {
  const { logout } = useAuth0()

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: `${process.env.REACT_APP_AUTH0_POST_LOGOUT_REDIRECT_URL}`,
      },
    })
  }

  return (
    <Menu>
      <Menu.Trigger>
        <UserProfileAvatarButton />
      </Menu.Trigger>
      <Menu.Items>
        <Menu.Item icon={faCalendar} label="My Events" />
        <Menu.Item icon={faUser} label="My Profile" />
        <Menu.Divider />
        <Menu.Item
          icon={faRightFromBracket}
          label="Logout"
          onClick={handleLogout}
        />
      </Menu.Items>
    </Menu>
  )
}

const UserProfileAvatarButton = React.forwardRef(
  function UserProfileAvatarButton(props, ref) {
    const { user } = useAuth0()

    return (
      <span ref={ref} className="relative inline-block">
        <img
          alt={user.name}
          src={user.picture}
          className="inline-block w-12 rounded-full border border-indigo-500 border-2 shadow shadow-indigo-500"
        />
        {props.alert && (
          <span className="absolute right-0 top-0 block h-3 w-3 rounded-full bg-red-400 ring-2 ring-white" />
        )}
      </span>
    )
  }
)
