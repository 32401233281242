import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/pro-solid-svg-icons'

export default function Team() {
  const trustees = [
    {
      name: 'Elizabeth Bale',
      role: 'Trustee',
    },
    {
      name: 'Dennis Elliott',
      role: 'Trustee',
    },
  ]

  const management = [
    {
      name: 'Kevin Moore',
      role: 'Chairperson',
      phone: '01458 223774',
    },
    {
      name: 'Margaret Hutchins',
      role: 'Booking Secretary',
      phone: '01458 224099',
    },
    {
      name: 'Terrie Elliss',
      role: 'Secretary',
      phone: '01458 224225',
    },
    {
      name: 'Phil Elliss',
      role: 'Treasurer',
      phone: '01458 224225',
    },
  ]

  const friends = [
    { name: 'Alison Edwards', role: 'Chairperson' },
    { name: 'Terrie Ellis', role: 'Co-secretary' },
    { name: 'Joey Moser', role: 'Co-secretary' },
    { name: 'Ollie Cook', role: 'Treasurer' },
    { name: 'Phil Ellis' },
    { name: 'Paula Baldock' },
    { name: 'Martin Baldock' },
    { name: 'Jennie Eastick' },
    { name: 'Nathalie Melville Geary' },
  ]

  return (
    <div className="text-wrapper text-sm border rounded-lg bg-white px-4 sm:px-6 py-4 sm:py-6 divide-y divide-gray-200">
      <div className="pb-4">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Trustees
        </h2>
        <p className="text-sm leading-8 text-gray-900 mb-4 ml-2">
          The Reading Room's charity structure is managed by the trustees.
        </p>
        <ul className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2">
          {trustees.map((person) => (
            <li key={person.name}>
              <div className="relative w-full h-72 overflow-hidden bg-gray-100 rounded-2xl dark:bg-gray-600 border-b sm:border-0">
                <svg
                  className="absolute w-80 h-80 text-gray-400 -left-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>

              <h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">
                {person.name}
              </h3>
              <p className="text-base leading-7 text-gray-600">{person.role}</p>
            </li>
          ))}
        </ul>
      </div>
      <div className="pt-4">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Management Committee
        </h2>
        <p className="text-sm leading-8 text-gray-900 mb-4 ml-2">
          The Management Committee handle the day-to-day operations of the
          Reading Room.
        </p>
        <ul className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2">
          {management.map((person) => (
            <li key={person.name}>
              <div className="relative w-full h-72 overflow-hidden bg-gray-100 rounded-2xl dark:bg-gray-600 border-b sm:border-0">
                <svg
                  className="absolute w-80 h-80 text-gray-400 -left-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>

              <h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">
                {person.name}
              </h3>
              <p className="text-base leading-7 text-gray-600">{person.role}</p>
              <p className="text-base leading-7 text-gray-600">
                <a href={'tel:' + person.phone} className="flex items-center">
                  <FontAwesomeIcon icon={faPhone} className="mr-1" />
                  {person.phone}
                </a>
              </p>
            </li>
          ))}
        </ul>
      </div>
      <div className="pt-4" id="friends">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Friends
        </h2>
        <p className="text-sm leading-8 text-gray-900 mb-4 ml-2">
          The Friends of the Reading Room are active encourage the use of the
          venue and raising money to support its operations. The Friends of the
          Reading room have so far contributed:
          <ul className="list-disc ml-4">
            <li>Tables</li>
            <li>Broadband and WiFi</li>
            <li>Fridge</li>
          </ul>
        </p>
        <ul className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2">
          {friends.map((person) => (
            <li key={person.name}>
              <div className="relative w-full h-72 overflow-hidden bg-gray-100 rounded-2xl dark:bg-gray-600 border-b sm:border-0">
                <svg
                  className="absolute w-80 h-80 text-gray-400 -left-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>

              <h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">
                {person.name}
              </h3>
              <p className="text-base leading-7 text-gray-600">{person.role}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
