import React from 'react'
import { NavLink } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'

export default function NavHeader() {
  return (
    <>
      <footer className="bg-indigo-900">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-4 md:flex md:items-center md:justify-between">
          <div className="justify-center space-x-6 space-y-2 text-center text-xs p-2">
            <p className="text-center text-xs text-indigo-300">
              &copy; 2024 The Reading Room Charlton Mackrell is a registered
              charity in England and Wales (no{' '}
              <a
                href="https://register-of-charities.charitycommission.gov.uk/charity-search/-/charity-details/3033959/charity-overview"
                className="hover:text-white"
              >
                1061743
              </a>
              ). All rights reserved.
            </p>
            <p className="text-center text-xs text-indigo-300">
              This website is kindly provided by the{' '}
              <HashLink
                to="/team#friends"
                className="text-indigo-300 hover:text-white"
              >
                Friends of the Reading Room
              </HashLink>
              .
            </p>
          </div>
          <div className="flex justify-center space-x-6 text-center text-xs p-2">
            <NavLink
              to="/privacy"
              end
              className="text-indigo-300 hover:text-white"
            >
              Privacy Policy
            </NavLink>
            <NavLink
              to="/terms"
              end
              className="text-indigo-300 hover:text-white"
            >
              Terms and Conditions
            </NavLink>
          </div>
        </div>
      </footer>
    </>
  )
}
