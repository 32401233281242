import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTablePicnic } from '@fortawesome/pro-solid-svg-icons'
import ColouredPill from './ColouredPill'
import { classNames } from '../utils'

export default function TablePill(props) {
  return (
    <ColouredPill
      className={classNames(
        props.className,
        'bg-purple-100 text-purple-800 ring-2 ring-purple-800'
      )}
    >
      <span className="flex items-center">
        <FontAwesomeIcon icon={faTablePicnic} />
        <span className="pl-2 hidden sm:inline">Tables</span>
      </span>
    </ColouredPill>
  )
}
