import React, { createContext, useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import useUserService from '../hooks/UserService'

// How often to check in with API for user metadata etc.
const CHECK_INTERVAL = 600

const UserProfileContext = createContext()
const { Provider } = UserProfileContext

const UserProfileProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(null)
  const [isStaff, setIsStaff] = useState(false)
  const [lastUpdated, setLastUpdated] = useState(0)
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0()
  const { fetchMe } = useUserService()

  useEffect(() => {
    const updateToken = async () => {
      const token = await getAccessTokenSilently()
      setAccessToken(token)
    }

    if (!isLoading && isAuthenticated) {
      updateToken()
    }
  }, [isLoading, isAuthenticated, getAccessTokenSilently, setAccessToken])

  useEffect(() => {
    const updateMe = async () => {
      if (isAuthenticated && accessToken) {
        const r = await fetchMe(accessToken)
        setIsStaff(r.is_staff)
        setLastUpdated(Date.now())
      }
    }

    if (isLoading) {
      return
    }

    if (lastUpdated && Date.now() - lastUpdated < CHECK_INTERVAL) {
      return
    } else {
      updateMe()
    }
  }, [isLoading, isAuthenticated, accessToken, fetchMe, lastUpdated])

  const profile = {
    accessToken,
    isStaff,
  }

  return <Provider value={profile}>{children}</Provider>
}

export { UserProfileContext, UserProfileProvider }
