import React from 'react'
import InfoBox from './alert/InfoBox'
import LoginButton from '../components/LoginButton'

export default function NotLoggedInRedirect({ to }) {
  return (
    <InfoBox>
      <p className="text-indigo-700 flex items-center w-full justify-between">
        <span className="pr-6">
          This page is not available until you login.
        </span>
        <LoginButton className="float-right">Login now &rarr;</LoginButton>
      </p>
    </InfoBox>
  )
}
