import React, { useEffect, useState } from 'react'
import { Await, useParams } from 'react-router-dom'
import SkeletonBookingList from '../components/SkeletonBookingList'
import useUserProfile from '../hooks/UserProfile'
import useUserService from '../hooks/UserService'

function User() {
  let { id } = useParams()
  const [user, setUser] = useState([])
  const { accessToken } = useUserProfile()
  const { fetchUser } = useUserService()

  useEffect(() => {
    const updateUser = async () => {
      if (accessToken) {
        const r = await fetchUser(accessToken, id)
        setUser(r)
      }
    }

    updateUser()
  }, [accessToken, fetchUser, id])

  // TODO: Add user's booking to profile
  // TODO: Merge auth0 user struct with that returned from API, here and in `pages/Users`

  return (
    <React.Suspense fallback={<SkeletonBookingList />}>
      <div>
        <ul className="divide-y divide-gray-200">
          <Await resolve={user}>
            {(resolved) => (
              <div className="overflow-hidden bg-white shadow rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                  <h3 className="truncate text-base font-semibold leading-6 text-gray-900">
                    {user.username} ({user.first_name} {user.last_name})
                  </h3>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6"></div>
              </div>
            )}
          </Await>
        </ul>
      </div>
    </React.Suspense>
  )
}

export default User
