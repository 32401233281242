import React, { useEffect, useState } from 'react'
import { Link, useAsyncValue } from 'react-router-dom'
import {
  add,
  eachDayOfInterval,
  endOfMonth,
  format,
  isFuture,
  isPast,
  isToday,
  parseISO,
  sub,
} from 'date-fns'
import NoEvents from '../components/NoEvents'
import { classNames, groupBy, sessionToColour } from './utils'
import SessionPill from './pills/SessionPill'

export default function MonthCalendar(props) {
  const bookings = useAsyncValue()
  const { month, year } = props
  const [selectedDay, setSelectedDay] = useState(
    format(new Date(), 'yyyy-MM-dd')
  )
  const [selectedDayEvents, setSelectedDayEvents] = useState([])
  const [days, setDays] = useState([])

  useEffect(() => {
    setDays(prepareBookings(bookings, year, month))
  }, [bookings, year, month])

  useEffect(() => {
    const d = days.find((el) => el.date === selectedDay)
    setSelectedDayEvents(d?.events)
  }, [selectedDay, days])

  return (
    <div className="lg:h-0 lg:min-h-[768px]">
      <div className="lg:flex lg:h-full lg:flex-col">
        <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
          <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
            <div className="bg-indigo-400 text-white py-2">
              M<span className="sr-only sm:not-sr-only">on</span>
            </div>
            <div className="bg-indigo-400 text-white py-2">
              T<span className="sr-only sm:not-sr-only">ue</span>
            </div>
            <div className="bg-indigo-400 text-white py-2">
              W<span className="sr-only sm:not-sr-only">ed</span>
            </div>
            <div className="bg-indigo-400 text-white py-2">
              T<span className="sr-only sm:not-sr-only">hu</span>
            </div>
            <div className="bg-indigo-400 text-white py-2">
              F<span className="sr-only sm:not-sr-only">ri</span>
            </div>
            <div className="bg-indigo-400 text-white py-2">
              S<span className="sr-only sm:not-sr-only">at</span>
            </div>
            <div className="bg-indigo-400 text-white py-2">
              S<span className="sr-only sm:not-sr-only">un</span>
            </div>
          </div>
          <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
            <div className="hidden w-full lg:grid lg:grid-cols-7 lg:gap-px">
              {days.map((day) => (
                <div
                  key={day.date}
                  className={classNames(
                    day.isToday
                      ? 'bg-indigo-200'
                      : day.isCurrentMonth
                        ? 'bg-white'
                        : 'bg-gray-50 text-gray-500',
                    'relative px-3 py-2'
                  )}
                >
                  <time
                    dateTime={day.date}
                    className={
                      day.isToday
                        ? 'flex h-6 w-6 items-center justify-center rounded-full bg-indigo-600 font-semibold text-white'
                        : undefined
                    }
                  >
                    {day.date.split('-').pop().replace(/^0/, '')}
                  </time>
                  <ol className="mt-2 group h-full">
                    {day.events.length > 0 &&
                      day.events.slice(0, 3).map((event) => (
                        <li
                          key={event.id}
                          className="flex items-center space-x-2"
                        >
                          <SessionPill session={event.session} small />
                          <a href={eventLink(event)} className="group flex">
                            <p className="flex-auto truncate font-medium text-gray-900 group-hover:text-indigo-600">
                              {event.summary}
                            </p>
                          </a>
                        </li>
                      ))}
                    {day.events.length > 3 && (
                      <li className="text-gray-500">
                        + {day.events.length - 3} more
                      </li>
                    )}
                    {day.events.length < 3 && isFuture(parseISO(day.date)) && (
                      <li
                        key="book"
                        className="flex items-center justify-center space-x-2 pt-2"
                      >
                        <Link
                          to={'/events/new'}
                          className="flex-none self-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white opacity-0 shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 focus:opacity-100 group-hover:opacity-100"
                        >
                          Book
                          <span className="sr-only"> {day.date}</span>
                        </Link>
                      </li>
                    )}
                  </ol>
                </div>
              ))}
            </div>
            <div className="isolate grid w-full grid-cols-7 gap-px lg:hidden">
              {days.map((day) => (
                <button
                  key={day.date}
                  type="button"
                  className={classNames(
                    day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
                    (day.date === selectedDay || day.isToday) &&
                      'font-semibold',
                    day.date === selectedDay && 'text-white',
                    day.date !== selectedDay &&
                      day.isToday &&
                      'text-indigo-600',
                    day.date !== selectedDay &&
                      day.isCurrentMonth &&
                      !day.isToday &&
                      'text-gray-900',
                    day.date !== selectedDay &&
                      !day.isCurrentMonth &&
                      !day.isToday &&
                      'text-gray-500',
                    'flex h-14 flex-col px-3 py-2 hover:bg-gray-100 focus:z-10'
                  )}
                  onClick={() => setSelectedDay(day.date)}
                >
                  <time
                    dateTime={day.date}
                    className={classNames(
                      day.date === selectedDay &&
                        'flex h-6 w-6 items-center justify-center rounded-full',
                      day.date === selectedDay &&
                        day.isToday &&
                        'bg-indigo-600',
                      day.date === selectedDay && !day.isToday && 'bg-gray-900',
                      'ml-auto'
                    )}
                  >
                    {day.date.split('-').pop().replace(/^0/, '')}
                  </time>
                  <span className="sr-only">{day.events.length} events</span>
                  {day.events.length > 0 && (
                    <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                      {day.events.map((event) => (
                        <span
                          key={event.id}
                          className={classNames(
                            'mx-0.5 mb-1 h-1.5 w-1.5 rounded-full',
                            sessionToColour(event.session)
                          )}
                        />
                      ))}
                    </span>
                  )}
                </button>
              ))}
            </div>
          </div>
        </div>
        {selectedDay && (
          <div className="px-4 py-10 sm:px-6 lg:hidden">
            <ol className="divide-y divide-gray-100 overflow-hidden rounded-lg bg-white text-sm shadow ring-1 ring-black ring-opacity-5">
              <li className="text-base font-semibold leading-6 text-white p-4 pr-6 bg-indigo-600">
                {format(parseISO(selectedDay), 'EEEE, do LLLL yyyy')}
              </li>
              {selectedDayEvents &&
                selectedDayEvents.length > 0 &&
                selectedDayEvents.map((event) => (
                  <li
                    key={event.id}
                    className="group flex p-4 pr-6 focus-within:bg-gray-50 hover:bg-gray-50"
                  >
                    <div className="flex-auto">
                      <p className="font-semibold text-gray-900">
                        {event.summary}
                      </p>
                      <time
                        dateTime={event.date}
                        className="mt-2 flex items-center text-gray-700"
                      >
                        <SessionPill session={event.session} />
                      </time>
                    </div>
                    <a
                      href={eventLink(event)}
                      className="ml-6 flex-none self-center rounded-md bg-indigo-600 px-3 py-2 font-semibold text-white opacity-0 shadow-sm hover:bg-indigo-500 focus:opacity-100 group-hover:opacity-100"
                    >
                      More info
                      <span className="sr-only"> about {event.summary}</span>
                    </a>
                  </li>
                ))}
              {selectedDayEvents && selectedDayEvents.length === 0 && (
                <li className="group flex p-8">
                  <div className="flex-auto">
                    {isPast(parseISO(selectedDay)) && <p>No events.</p>}
                    {isFuture(parseISO(selectedDay)) && <NoEvents />}
                  </div>
                </li>
              )}
            </ol>
          </div>
        )}
      </div>
    </div>
  )
}

function eventLink(event) {
  return '/event/' + event.id
}

function prepareBookings(bookings, year, month) {
  let firstDayOfMonth = new Date(year, month - 1, 1)
  let days = []

  // group bookings by date
  let bookingsByDate = groupBy(bookings, 'date')

  // convert bookings into a sparse list grouped by date
  let lastDayOfMonth = endOfMonth(firstDayOfMonth)
  let mondayBefore = sub(firstDayOfMonth, {
    days: (((firstDayOfMonth.getDay() - 1) % 7) + 7) % 7,
  })
  let sundayAfter = add(lastDayOfMonth, {
    days: (7 - lastDayOfMonth.getDay()) % 7,
  })
  eachDayOfInterval({ start: mondayBefore, end: sundayAfter }).forEach((el) => {
    let d = format(el, 'yyyy-MM-dd')
    let events = bookingsByDate[d] || []
    let day = {
      date: d,
      events: events.sort(sortBySession),
      isCurrentMonth: el.getMonth() === month - 1,
      isToday: isToday(parseISO(d)),
    }
    days.push(day)
  })

  return days
}

function sortBySession(a, b) {
  /* Order is (M)orning
              (A)fternoon
              (E)vening    */

  if (a['session'] === 'M') {
    return -1
  } else if (a['session'] === 'A' && b['session'] === 'E') {
    return -1
  } else if (a['session'] === 'E') {
    return 1
  }

  return 0
}
