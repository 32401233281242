import React, { Fragment } from 'react'
import MorningPill from './MorningPill'
import AfternoonPill from './AfternoonPill'
import EveningPill from './EveningPill'

export default function SessionPill(props) {
  switch (props.session) {
    case 'M':
      return <MorningPill {...props} />
    case 'A':
      return <AfternoonPill {...props} />
    case 'E':
      return <EveningPill {...props} />
    default:
      return <Fragment />
  }
}
