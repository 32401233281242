import React, { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import UserList from '../components/UserList'
import NotLoggedInRedirect from '../components/NotLoggedInRedirect'
import useUserProfile from '../hooks/UserProfile'
import useUserService from '../hooks/UserService'

function Users() {
  const [users, setUsers] = useState([])
  const { accessToken } = useUserProfile()
  const { isAuthenticated } = useAuth0()
  const { fetchUsers } = useUserService()

  useEffect(() => {
    const updateUsers = async () => {
      if (accessToken) {
        const r = await fetchUsers(accessToken)
        setUsers(r)
      }
    }

    updateUsers()
  }, [accessToken, fetchUsers])

  if (!isAuthenticated) {
    return <NotLoggedInRedirect />
  }

  return <UserList users={users} />
}

export default Users
