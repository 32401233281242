import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMugTeaSaucer } from '@fortawesome/pro-solid-svg-icons'
import ColouredPill from './ColouredPill'

import { classNames } from '../utils'

export default function AfternoonPill(props) {
  let withText = !props.hasOwnProperty('small')
  return (
    <ColouredPill
      className={classNames(
        props.className,
        'bg-blue-100 text-blue-800 ring-2 ring-blue-800'
      )}
    >
      <span className="flex items-center">
        <FontAwesomeIcon icon={faMugTeaSaucer} />
        {withText && <span className="pl-2 hidden sm:inline">Afternoon</span>}
      </span>
    </ColouredPill>
  )
}
