export default function Facilities() {
  return (
    <div className="text-wrapper text-sm text-gray-900 text-justify border rounded-lg bg-white space-y-4 px-4 sm:px-6 py-4 sm:py-6 leading-relaxed">
      <p>
        The Reading Room is pleased to offer the following facilities
        free-of-charge with each booking:
      </p>
      <ul className="list-disc ml-4">
        <li>A variety of table sizes (rectangular and square)</li>
        <li>Chairs</li>
        <li>Use of the newly-refurbished kitchen</li>
        <li>Crockery and cutlery</li>
        <li>WiFi</li>
        <li>Use of the stage area</li>
      </ul>
    </div>
  )
}
