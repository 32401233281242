import React from 'react'
import { Await } from 'react-router-dom'
import SkeletonBookingList from './SkeletonBookingList'
import PublicBookingListItem from './PublicBookingListItem'
import NoEvents from './NoEvents'

export default function EventList({ bookings }) {
  return (
    <React.Suspense fallback={<SkeletonBookingList />}>
      <Await resolve={bookings}>
        {(resolved) => (
          <>
            {resolved.length > 0 && (
              <div className="border rounded-lg bg-white">
                <ul className="divide-y divide-gray-200 rounded-lg shadow">
                  {resolved.map((booking, i) => (
                    <PublicBookingListItem key={i} booking={booking} />
                  ))}
                </ul>
              </div>
            )}
            {resolved.length === 0 && <NoEvents />}
          </>
        )}
      </Await>
    </React.Suspense>
  )
}
