import React from 'react'
import { useField } from 'formik'
import Error from './Error.js'

export default function RRCheckbox({ children, help_text, ...props }) {
  const [field, meta] = useField({ ...props, type: 'checkbox' })
  return (
    <div className="relative flex gap-x-3">
      <div className="flex h-6 items-center">
        {meta.touched && meta.error && <Error meta={meta} />}
        <input
          type="checkbox"
          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
          {...field}
          {...props}
        />
      </div>
      <div className="text-sm leading-6">
        <label
          htmlFor={props.id || props.name}
          className="font-medium text-gray-900"
        >
          {children}
        </label>
        <p className="text-gray-500">{help_text}</p>
      </div>
    </div>
  )
}
