import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronRight,
  faEnvelope,
  faUser,
} from '@fortawesome/pro-solid-svg-icons'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

export default function UserListItem({ user, ...props }) {
  dayjs.extend(relativeTime)

  return (
    <li key={user.email} className="relative flex justify-between gap-x-6 py-5">
      <div className="flex min-w-0 gap-x-4">
        <div className="min-w-0 flex-auto ml-5">
          <p className="text-sm font-semibold leading-6 text-gray-900">
            <Link to={`/user/${user.id}`}>
              <span className="absolute inset-x-0 -top-px bottom-0" />
              {user.first_name || 'Unknown'} {user.last_name || 'User'}
            </Link>
          </p>
          {user.email && (
            <p className="mt-1 flex text-xs leading-5 text-gray-500">
              <FontAwesomeIcon icon={faEnvelope} className="mr-2" fixedWidth />
              <a
                href={`mailto:${user.email}`}
                className="relative truncate hover:underline"
              >
                {user.email}
              </a>
            </p>
          )}
          <p className="mt-1 flex text-xs leading-5 text-gray-500">
            <FontAwesomeIcon icon={faUser} className="mr-2" fixedWidth />
            {user.username}
          </p>
        </div>
      </div>
      <div className="flex shrink-0 items-center gap-x-4">
        <div className="hidden sm:flex sm:flex-col sm:items-end">
          <p className="text-sm leading-6 text-gray-900">
            {user.is_staff ? 'Staff' : 'Regular'}
          </p>
          <p className="mt-1 text-xs leading-5 text-gray-500">
            Joined {dayjs(user.date_joined).fromNow()}
          </p>
        </div>
        <FontAwesomeIcon icon={faChevronRight} className="mr-5 text-gray-400" />
      </div>
    </li>
  )
}
