import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons'

export default function ErrorBox({ children, ...props }) {
  return (
    <div className="border rounded-lg bg-red-50 p-4">
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            className="text-red-400"
          />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between items-center">
          {children}
        </div>
      </div>
    </div>
  )
}
