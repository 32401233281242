import React, { Fragment, useEffect, useState } from 'react'
import { Await, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendar,
  faCircleQuestion,
  faChevronDown,
  faRotateLeft,
  faThumbsDown,
  faThumbsUp,
  faTrash,
} from '@fortawesome/pro-solid-svg-icons'
import { useAuth0 } from '@auth0/auth0-react'
import SkeletonBookingList from '../components/SkeletonBookingList'
import Button from '../components/Button'
import Modal from '../components/Modal'
import Menu from '../components/Menu'
import InfoBox from '../components/alert/InfoBox'
import ErrorBox from '../components/alert/ErrorBox'
import SessionPill from '../components/pills/SessionPill'
import PublicPill from '../components/pills/PublicPill'
import PrivatePill from '../components/pills/PrivatePill'
import TablePill from '../components/pills/TablePill'
import CrockeryPill from '../components/pills/CrockeryPill'
import ApprovedPill from '../components/pills/ApprovedPill'
import RejectedPill from '../components/pills/RejectedPill'
import PendingApprovalPill from '../components/pills/PendingApprovalPill'
import useUserProfile from '../hooks/UserProfile'
import useBookingService from '../hooks/BookingService'

function ConfirmDeleteModal(props) {
  return (
    <Modal open={props.open} onClose={props.onClose}>
      <Modal.Title>
        <FontAwesomeIcon icon={faCircleQuestion} className="pr-3" size="xl" />
        Delete Booking Confirmation
      </Modal.Title>
      <Modal.Body>
        <p>This will permanently delete the booking.</p>
        <p>
          Are you sure you want to delete this booking? It will be permanently
          removed.
        </p>
      </Modal.Body>
      <Modal.Buttons>
        <Button theme="deemphasise" onClick={props.onClose} data-autofocus>
          No, cancel
        </Button>
        <Button theme="danger" onClick={props.onConfirm}>
          <FontAwesomeIcon icon={faTrash} className="pr-1" />
          Yes, delete!
        </Button>
      </Modal.Buttons>
    </Modal>
  )
}

function BookingMenu(props) {
  return (
    <Menu>
      <Menu.Trigger>
        <span className="sr-only">Open actions</span>
        <Button>
          Actions
          <FontAwesomeIcon icon={faChevronDown} className="ml-2" />
        </Button>
      </Menu.Trigger>
      <Menu.Items>
        <Menu.Item
          onClick={props.onClickApprove}
          disabled={props.booking.approved_ts}
          icon={faThumbsUp}
          label="Approve"
        />
        <Menu.Item
          onClick={props.onClickReject}
          disabled={props.booking.rejected_ts}
          icon={faThumbsDown}
          label="Reject"
        />
        <Menu.Divider />
        <Menu.Item
          onClick={props.onClickReset}
          disabled={!props.booking.approved_ts && !props.booking.rejected_ts}
          icon={faRotateLeft}
          label="Reset"
        />
        <Menu.Divider />
        <Menu.Item
          onClick={props.onClickDelete}
          icon={faTrash}
          label="Delete"
        />
      </Menu.Items>
    </Menu>
  )
}

function Booking() {
  let { id } = useParams()
  const [booking, setBooking] = useState([])
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false)
  const [bookingIdToDelete, setBookingIdToDelete] = useState(null)
  const [deleteSuccess, setDeleteSuccess] = useState(null)
  const { isLoading } = useAuth0()
  const { accessToken, isStaff } = useUserProfile()
  const {
    fetchBooking,
    approveBooking,
    deleteBooking,
    rejectBooking,
    resetBooking,
  } = useBookingService()

  useEffect(() => {
    if (!isLoading) {
      setBooking(fetchBooking(accessToken, id))
    }
  }, [isLoading, fetchBooking, accessToken, id])

  const handleClickDelete = (id) => {
    setBookingIdToDelete(id)
    setShowDeleteConfirmationModal(true)
  }

  const handleClickConfirm = () => {
    deleteBooking(accessToken, bookingIdToDelete)
      .then((s) => {
        setDeleteSuccess(s)
        setBooking(null)
      })
      .finally(() => {
        setShowDeleteConfirmationModal(false)
      })
  }

  return (
    <>
      {deleteSuccess === true && (
        <InfoBox>
          <p className="text-indigo-700">Booking successfully deleted.</p>
        </InfoBox>
      )}
      {deleteSuccess === false && (
        <ErrorBox>
          <p className="text-red-700">
            There was an error deleting the booking. Please try again.
          </p>
        </ErrorBox>
      )}
      {deleteSuccess === null && (
        <>
          <ConfirmDeleteModal
            open={showDeleteConfirmationModal}
            onClose={() => setShowDeleteConfirmationModal(false)}
            onConfirm={() => handleClickConfirm()}
          />
          <pre>{showDeleteConfirmationModal}</pre>
          <React.Suspense fallback={<SkeletonBookingList />}>
            <div>
              <ul className="divide-y divide-gray-200">
                <Await resolve={booking}>
                  {(resolved) => (
                    <div className="overflow-hidden bg-white shadow rounded-lg">
                      <div className="px-4 py-5 sm:px-6 justify-between flex items-center">
                        <h3 className="truncate text-base font-semibold leading-6 text-gray-900 flex items-center">
                          <FontAwesomeIcon icon={faCalendar} className="mr-2" />
                          {resolved.summary}
                        </h3>
                        {isStaff && (
                          <BookingMenu
                            booking={resolved}
                            onClickApprove={() =>
                              setBooking(
                                approveBooking(accessToken, resolved.id)
                              )
                            }
                            onClickReject={() =>
                              setBooking(
                                rejectBooking(accessToken, resolved.id)
                              )
                            }
                            onClickReset={() =>
                              setBooking(resetBooking(accessToken, resolved.id))
                            }
                            onClickDelete={() =>
                              handleClickDelete(accessToken, resolved.id)
                            }
                          />
                        )}
                      </div>
                      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Date & Session
                            </dt>
                            <dd className="ml-2 mt-1 text-sm text-gray-900 flex items-center">
                              {resolved.date}
                              <SessionPill
                                session={resolved.session}
                                className="ml-2"
                              />
                            </dd>
                          </div>
                          <div className="sm:col-span-1">
                            {isStaff && (
                              <>
                                <dt className="text-sm font-medium text-gray-500">
                                  Booked By
                                </dt>
                                <dd className="ml-2 mt-1 text-sm text-gray-900">
                                  {resolved.creator?.first_name}
                                  {resolved.creator?.last_name}
                                </dd>
                              </>
                            )}
                          </div>
                          <div className="sm:col-span-1">
                            {isStaff && (
                              <>
                                <dt className="text-sm font-medium text-gray-500">
                                  Approval Status
                                </dt>
                                <dd className="ml-2 mt-1">
                                  {!resolved.approved_ts &&
                                    !resolved.rejected_ts && (
                                      <PendingApprovalPill />
                                    )}
                                  {resolved.approved_ts && <ApprovedPill />}
                                  {resolved.rejected_ts && <RejectedPill />}
                                </dd>
                              </>
                            )}
                          </div>
                          <div className="sm:col-span-1">
                            {isStaff && (
                              <>
                                <dt className="text-sm font-medium text-gray-500">
                                  Extras
                                </dt>
                                <dd className="ml-2 mt-1 space-x-2">
                                  {resolved.need_crockery && <CrockeryPill />}
                                  {resolved.need_tables && <TablePill />}
                                </dd>
                              </>
                            )}
                          </div>
                          <div className="sm:col-span-1">
                            {isStaff && (
                              <>
                                <dt className="text-sm font-medium text-gray-500">
                                  Visibility
                                </dt>
                                <dd className="ml-2 mt-1">
                                  {resolved.is_private ? (
                                    <PrivatePill />
                                  ) : (
                                    <PublicPill />
                                  )}
                                </dd>
                              </>
                            )}
                          </div>
                        </dl>
                      </div>
                      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          Details
                        </dt>
                        <dd className="ml-2 mt-1 text-sm text-gray-900 italic">
                          {resolved.description}
                        </dd>
                      </div>
                    </div>
                  )}
                </Await>
              </ul>
            </div>
          </React.Suspense>
        </>
      )}
    </>
  )
}

export default Booking
