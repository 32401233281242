import React from 'react'
import { useField } from 'formik'
import Error from './Error.js'
import { classNames } from '../utils'

export default function RRTextArea({ label, help_text, ...props }) {
  const [field, meta] = useField(props)
  return (
    <div className="relative">
      <label
        htmlFor={props.id || props.name}
        className={classNames(
          'absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium',
          meta.touched && meta.error ? 'text-red-600' : 'text-gray-900'
        )}
      >
        {label}
      </label>
      <div>
        <textarea
          className={classNames(
            'text-input block w-full py-1.5 px-3 rounded-md border-0 text-gray-900 shadow-sm ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6',
            meta.touched && meta.error
              ? 'ring-2 ring-red-300'
              : 'ring-1 ring-gray-300'
          )}
          {...field}
          {...props}
        />
        {meta.touched && meta.error && <Error meta={meta} />}
      </div>
      <p className="text-sm leading-6 text-gray-600">{help_text}</p>
    </div>
  )
}
