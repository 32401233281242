import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons'
import { isRouteErrorResponse, useRouteError } from 'react-router-dom'

export default function ErrorBoundary() {
  const error = useRouteError()
  return (
    <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
      <div className="px-4 py-5 sm:px-6">
        <h1 className="text-2xl flex items-center text-red-600 font-bold">
          <FontAwesomeIcon icon={faTriangleExclamation} />
          <div className="pl-2">Oops!</div>
        </h1>
      </div>
      <div className="px-4 py-5 sm:p-6">
        <h2>
          {isRouteErrorResponse(error) ? (
            <>
              <span className="text-lg font-bold">{error.status}</span>
              <span className="p-2">/</span>
              <span>{error.statusText}</span>
            </>
          ) : (
            <span className="text-lg font-bold">
              Something unexpected happened
            </span>
          )}
        </h2>
        {isRouteErrorResponse(error) ? (
          error.data?.message && <span>{error.data.message}</span>
        ) : (
          <p className="font-mono whitespace-pre-wrap">
            {JSON.stringify(error, null, 2)}
          </p>
        )}
      </div>
    </div>
  )
}
