import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarClock } from '@fortawesome/pro-solid-svg-icons'
import ColouredPill from './ColouredPill'
import { classNames } from '../utils'

export default function PendingApprovalPill(props) {
  return (
    <ColouredPill
      className={classNames(
        props.className,
        'bg-orange-100 text-orange-800 ring-2 ring-orange-800'
      )}
    >
      <span className="flex items-center">
        <FontAwesomeIcon icon={faCalendarClock} />
        <span className="pl-2 hidden sm:inline">Pending Approval</span>
      </span>
    </ColouredPill>
  )
}
