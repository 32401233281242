import {
  makeGetCall,
  makePatchCall,
  makeDeleteCall,
  makePostCall,
} from './External'

export function createBooking(token, data) {
  const url = '/bookings/'
  return makePostCall(token, url, data)
}

export function fetchBookings(token) {
  const url = '/bookings/'
  return makeGetCall(token, url)
}

export function fetchBooking(token, id) {
  const url = '/booking/' + id + '/'
  return makeGetCall(token, url)
}

export function fetchBookingsForMonth(token, year, month) {
  const url = '/bookings/?year=' + year + '&month=' + month
  return makeGetCall(token, url)
}

export function fetchUpcomingBookings(token) {
  const url = '/bookings/?days=31'
  return makeGetCall(token, url)
}

export function approveBooking(token, id) {
  const url = '/booking/' + id + '/'
  return makePatchCall(token, url, {
    approved_ts: new Date(),
    rejected_ts: null,
  })
}

export function rejectBooking(token, id) {
  const url = '/booking/' + id + '/'
  return makePatchCall(token, url, {
    approved_ts: null,
    rejected_ts: new Date(),
  })
}

export function resetBooking(token, id) {
  const url = '/booking/' + id + '/'
  return makePatchCall(token, url, { approved_ts: null, rejected_ts: null })
}

export function deleteBooking(token, id) {
  const url = '/booking/' + id + '/'
  return makeDeleteCall(token, url)
}

export function checkSessionAvailability(token, date, session) {
  const url = '/session/check_session_available/'
  return makePostCall(token, url, {
    date: date,
    session: session,
  })
}
