import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useLocation } from 'react-router-dom'
import Button from '../components/Button'

export default function LoginButton({ children, next }) {
  const defaultMessage = 'Login'
  const { loginWithRedirect } = useAuth0()
  const location = useLocation()

  const handleLogin = async (next) => {
    await loginWithRedirect({
      appState: {
        returnTo: next || location.pathname,
      },
    })
  }

  return (
    <Button
      onClick={() => {
        handleLogin(next)
      }}
    >
      {children || defaultMessage}
    </Button>
  )
}
