export default function History() {
  return (
    <div className="text-wrapper text-sm text-gray-900 text-justify border rounded-lg bg-white space-y-4 px-4 sm:px-6 py-4 sm:py-6 leading-relaxed">
      <img
        src="images/readingroom.jpg"
        alt="Photograph of the Reading Room"
        className="float-none object-center sm:float-right sm:object-none sm:m-4 rounded-lg"
      />
      <p>
        The Reading Room was built in the early 1850s by James Snaith Brymer and
        John Brymer, in memory of their brother The Venerable William Thomas
        Parr Brymer, Archdeacon of Bath. It was built to encourage adult
        literacy, and to provide labourers engaged with construction of railways
        in the locality with an alternative to the public house. From 1856, the
        Reading Room functioned as the "West Charlton Parochial Library and
        Reading Rooms".
      </p>
      <p>
        The Brymer family owned the Reading Room building until 1937, when
        Wilfred John Brymer gave it in trust for use of the parishioners of
        Charlton Mackrell and Charlton Adam. The freehold was passed to the
        Diocese of Bath and Wells, which transferred full and complete
        responsibility for the building to a Charitable Trust. The trustees were
        those holding office as Incumbent and Churchwardens at any time, plus
        representatives from the Parish Council and the local British Legion.
        The day-to-day management of the hall is the responsibility of a
        Management Committee appointed by the Trustees.
      </p>
      <p>
        The hall is registered as a charity, and the Trustees are responsible
        for keeping the hall as a going concern. It has no regular income other
        than the proceeds generated from hiring out the hall. The building has
        been extended and modernised by groups of volunteers several times over
        the years since it was built.
      </p>
      <p>
        The hall was rescued from decline around the turn of the century,
        largely due to fundraising efforts by the Friends of the Reading Room
        aided by the award of grants from local organisations. The Reading Room
        also received half of the proceeds from the sale of the Temperance Hall
        in Charlton Adam. As a result, the kitchen was renovated, and a new
        heating system, replacement windows, and a disabled toilet were
        installed. The stage, together with sound and lighting systems, was
        funded and built by the Charltons Amateur Dramatic Society (CHADS).
      </p>
      <p>
        The local Churches have been regular users, but have provided no funding
        to maintain the Hall other than paying the normal hire charges. The Hall
        is used as a polling station for local and national elections, and is
        available for hire for private functions.
      </p>
      <p>
        Groups and organisations that have used the Reading Room over the years
        include:
      </p>
      <ul className="list-disc ml-4">
        <li>The Charltons Parish Council</li>
        <li>The Parochial Church Council</li>
        <li>Boy Scouts and Charltons Brownies</li>
        <li>Village Café and Winter Warmers lunches</li>
        <li>Baby and Toddler Groups</li>
        <li>Charlton Mackrell School and Preschool</li>
        <li>Charltons Churches for services and Sunday School</li>
        <li>Historical and Horticultural Societies, Garden Groups</li>
        <li>Charltons Amateur Dramatic Society</li>
        <li>Scottish, Ballroom and Country &amp; Western Dancing</li>
        <li>
          Charltons' Art Group, Pottery classes, Craft classes, Quilting group
        </li>
        <li>Tai Chi, Yoga and Pilates classes</li>
        <li>The Women’s Institute</li>
        <li>Moviola film screenings</li>
      </ul>
    </div>
  )
}
