import axios from 'axios'

export function makeGetCall(token, partialUrl) {
  return makeCall(token, partialUrl, 'GET')
}

export function makePostCall(token, partialUrl, data) {
  return makeCall(token, partialUrl, 'POST', data)
}

export function makePatchCall(token, partialUrl, data) {
  return makeCall(token, partialUrl, 'PATCH', data)
}

export function makeDeleteCall(token, partialUrl) {
  return makeCall(token, partialUrl, 'DELETE')
}

function makeCall(token, partialUrl, method, data) {
  const baseUrl = `${process.env.REACT_APP_API_URL}/api`

  const client = axios.create({
    baseURL: baseUrl,
  })

  const config = {
    url: partialUrl,
    method: method,
    data: data,
  }

  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`,
    }
  }

  return client
    .request(config)
    .catch(function (error) {
      throw error.toJSON()
    })
    .then(function (response) {
      return response.data
    })
}
