import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/pro-solid-svg-icons'
import ColouredPill from './ColouredPill'
import { classNames } from '../utils'

export default function PublicPill(props) {
  return (
    <ColouredPill
      className={classNames(
        props.className,
        'bg-green-100 text-green-800 ring-2 ring-green-800'
      )}
    >
      <span className="flex items-center">
        <FontAwesomeIcon icon={faEye} />
        <span className="pl-2 hidden sm:inline">Public</span>
      </span>
    </ColouredPill>
  )
}
