import React, { Fragment } from 'react'
import {
  createBrowserRouter,
  Link,
  Outlet,
  RouterProvider,
} from 'react-router-dom'
import NavHeader from './components/NavHeader'
import Booking from './pages/Booking'
import BookingForm from './pages/BookingForm'
import Callback from './pages/Callback'
import Contact from './pages/Contact'
import Events from './pages/Events'
import Footer from './components/Footer'
import History from './pages/History'
import Facilities from './pages/Facilities'
import Privacy from './pages/Privacy'
import UpcomingEvents from './pages/UpcomingEvents'
import User from './pages/User'
import Users from './pages/Users'
import Team from './pages/Team'
import Terms from './pages/Terms'
import Breadcrumbs from './components/Breadcrumbs'
import ErrorBoundary from './components/ErrorBoundary'
import { UserProfileProvider } from './components/UserProfileProvider'
import { Auth0ProviderWithNavigate } from './auth0-provider-with-navigate.js'

const HeaderLayout = () => {
  return (
    <Auth0ProviderWithNavigate>
      <UserProfileProvider>
        <NavHeader />
        <div className="mx-auto max-w-7xl py-2 px-2 sm:py-6 sm:px-6 lg:py-8 lg:px-8">
          <Breadcrumbs />
          <Outlet />
        </div>
        <Footer />
      </UserProfileProvider>
    </Auth0ProviderWithNavigate>
  )
}

const ErrorLayout = () => (
  <Auth0ProviderWithNavigate>
    <UserProfileProvider>
      <NavHeader />
      <div className="mx-auto max-w-7xl py-2 px-2 sm:py-6 sm:px-6 lg:py-8 lg:px-8">
        <ErrorBoundary />
      </div>
      <Footer />
    </UserProfileProvider>
  </Auth0ProviderWithNavigate>
)

const router = createBrowserRouter([
  {
    element: <HeaderLayout />,
    errorElement: <ErrorLayout />,
    path: '/',
    children: [
      {
        index: true,
        element: <UpcomingEvents />,
        handle: { crumb: () => <Link to="/">Upcoming Events</Link> },
      },
      {
        path: 'history',
        element: <History />,
        handle: { crumb: () => <Link to="/history">History</Link> },
      },
      {
        path: 'facilities',
        element: <Facilities />,
        handle: { crumb: () => <Link to="/facilities">Facilities</Link> },
      },
      {
        path: 'team',
        element: <Team />,
        handle: { crumb: () => <Link to="/team">Team</Link> },
      },
      {
        path: 'events',
        handle: { crumb: () => <Link to="/events">Events</Link> },
        children: [
          {
            index: true,
            element: <Events />,
          },
          {
            path: ':year?/:month?',
            element: <Events />,
          },
          {
            path: 'new',
            element: <BookingForm />,
            handle: { crumb: () => <Link to="/events/new">New Booking</Link> },
          },
        ],
      },
      {
        path: 'event/:id',
        element: <Booking />,
      },
      {
        path: 'users',
        element: <Users />,
        handle: { crumb: () => <Link to="/users">Users</Link> },
      },
      {
        path: 'user/:id',
        element: <User />,
      },
      {
        path: 'callback',
        element: <Callback />,
      },
      {
        path: 'privacy',
        element: <Privacy />,
        handle: { crumb: () => <Link to="/privacy">Privacy Policy</Link> },
      },
      {
        path: 'terms',
        element: <Terms />,
        handle: { crumb: () => <Link to="/terms">Terms and Conditions</Link> },
      },
      {
        path: 'contact',
        element: <Contact />,
        handle: { crumb: () => <Link to="/contact">Contact Us</Link> },
      },
    ],
  },
])

export default function App() {
  return (
    <>
      <div className="min-h-full">
        <RouterProvider router={router} />
      </div>
    </>
  )
}
