import React from 'react'
import { Await } from 'react-router-dom'
import SkeletonBookingList from './SkeletonBookingList'
import UserListItem from './UserListItem'

export default function UserList({ users }) {
  return (
    <>
      <React.Suspense fallback={<SkeletonBookingList />}>
        <Await resolve={users}>
          {(resolved) => (
            <div className="border rounded-lg bg-white">
              <ul className="divide-y divide-gray-200 rounded-lg shadow">
                {resolved.length > 0 &&
                  resolved.map((user, i) => (
                    <UserListItem key={i} user={user} />
                  ))}
              </ul>
            </div>
          )}
        </Await>
      </React.Suspense>
    </>
  )
}
